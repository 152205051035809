import Logo from "./Logo"
import Navigation from "./Navigation"
import { css } from "@emotion/core"
import tw from "twin.macro"
import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import { Menu } from "react-feather"

const Header: React.FC = () => {
  const { ref, inView: onTop } = useInView({ initialInView: true })

  const [navOpen, setNavOpen] = useState(false)

  return (
    <>
      <div tw="absolute top-0 w-full h-px" ref={ref} />
      <header
        css={[
          tw`sticky top-0 z-40 w-full px-2 lg:px-0 py-2 md:py-0 bg-white shadow-lg`,
        ]}
      >
        <div tw="container">
          <div tw="flex items-center gap-6 md:justify-between">
            <div tw="md:hidden">
              <div
                tw="flex justify-start items-center p-4
                transform-gpu transition ease-out duration-300
                cursor-pointer"
                css={[navOpen && tw`rotate-90`]}
                onClick={() => setNavOpen(true)}
              >
                <Menu />
              </div>
            </div>
            <div tw="flex items-start justify-center md:justify-start">
              <div tw="flex-grow-0">
                <Logo />
              </div>
            </div>
            <div tw="flex h-full items-end">
              <div tw="hidden md:block">
                <Navigation />
              </div>
            </div>
            <div
              tw="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-filter backdrop-blur opacity-0 pointer-events-none transition duration-300 ease-out"
              css={[navOpen && tw`pointer-events-auto opacity-100`]}
              onClick={() => setNavOpen(false)}
            />
            <div tw="fixed left-0 bottom-0 w-full md:hidden">
              <div
                tw="absolute bottom-0 w-full bg-white shadow-lg transform-gpu translate-y-full transition duration-300 ease-out"
                css={[navOpen && tw`block translate-y-0`]}
                onClick={() => setNavOpen(false)}
              >
                <Navigation />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
