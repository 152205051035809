import { css } from "@emotion/react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "twin.macro"
import tw from "twin.macro"

interface SiteMetadata {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const Logo: React.FunctionComponent = () => {
  const data = useStaticQuery<SiteMetadata>(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Link to="/" tw="flex">
      <StaticImage
        src="../images/logo.png"
        alt={data.site.siteMetadata.title}
        loading="eager"
        layout="constrained"
        placeholder="tracedSVG"
        objectFit="contain"
        height={64}
      />
    </Link>
  )
}

export default Logo
