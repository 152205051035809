import * as React from "react"
import tw, { GlobalStyles } from "twin.macro"
import { Global } from "@emotion/react"
import Header from "./Header"
import Footer from "./Footer"

const Layout: React.FC = ({ children }) => {
  return (
    <div tw="flex flex-col min-h-screen">
      <Global
        styles={{
          html: tw`min-h-screen h-full bg-gray-200`,
          "#gatsby-focus-wrapper": tw`min-h-screen`,
          "#___gatsby": tw`min-h-screen`,
          body: tw`min-h-screen`,
        }}
      />
      <GlobalStyles />
      <Header />
      <main tw="flex flex-col flex-grow">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
