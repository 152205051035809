// custom CSS styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/thumbs"
import "swiper/css/controller"

import "./src/css/style.css"
import React from "react"
import Layout from "./src/components/layout"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}
