import NavigationItem from "./NavigationItem"
import "twin.macro"

export interface NavigationProps {}

const Navigation: React.FunctionComponent<NavigationProps> = () => {
  return (
    <ul tw="flex flex-col items-stretch md:flex-row">
      <NavigationItem to="/">Home</NavigationItem>
      <NavigationItem to="/gallery/">Gallery</NavigationItem>
      <NavigationItem to="/about/">About</NavigationItem>
      <NavigationItem to="/contact/">Contact</NavigationItem>
    </ul>
  )
}

export default Navigation
