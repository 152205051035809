import { Link } from "gatsby"
import tw from "twin.macro"

export interface NavigationItemProps {
  to: string
}

const NavigationItem: React.FunctionComponent<NavigationItemProps> = ({
  children,
  to,
}) => {
  return (
    <li>
      <Link
        tw="flex justify-center px-4 py-6 lg:px-6 uppercase text-sm font-display font-extralight tracking-[0.2em] hover:(bg-primary-light) transition duration-300 ease-out"
        activeStyle={tw`font-bold`}
        to={to}
      >
        {children}
      </Link>
    </li>
  )
}

export default NavigationItem
