import { Link } from "gatsby"
import GatsbyLink from "gatsby-link"
import { Instagram } from "react-feather"
import "twin.macro"

const Footer: React.FC = () => {
  return (
    <footer tw="bg-white">
      <div tw="container flex flex-col items-center gap-2 lg:gap-4 w-full py-4 lg:py-6 text-gray-500">
        <a
          href="https://www.instagram.com/santinasart"
          target="_blank"
          tw="flex items-center"
        >
          <Instagram tw="text-blue-500" />
          <div tw="ml-1 text-blue-500 hover:text-blue-400">santinasart</div>
        </a>
        <div tw="flex gap-2">
          <GatsbyLink to="/legal-notice">Legal Notice</GatsbyLink>
          <div>&bull;</div>
          <GatsbyLink to="/privacy">Privacy Policy</GatsbyLink>
        </div>
        <div>
          Santinas Art {new Date().getFullYear()} &copy; All rights reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer
